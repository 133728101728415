// components/Header.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';
import MQTTService from './MQTTService'; // MQTT 서비스 가져오기

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState<boolean>(false);
  const [signupOpen, setSignupOpen] = useState<boolean>(false);
  const [id, setId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoggedIn, setIsLoggedIn] = useState(false); // 로그인 상태 확인
  const [isConnected, setIsConnected] = useState(false); // MQTT 연결 상태
  const navigate = useNavigate();

  useEffect(() => {
    const mqttClient = MQTTService.getInstance().getClient();

    // MQTT 연결 상태 이벤트 핸들러 등록
    const handleConnect = () => setIsConnected(true);
    const handleDisconnect = () => setIsConnected(false);

    mqttClient.on('connect', handleConnect);
    mqttClient.on('close', handleDisconnect);

    // 클린업
    return () => {
      mqttClient.off('connect', handleConnect);
      mqttClient.off('close', handleDisconnect);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogin = async () => {
    try {
      // Step 1: Check if the email exists
      const checkUserResponse = await fetch('https://ai.doowon.ac.kr:1804/api/checkUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: id }),
      });

      const userCheck = await checkUserResponse.json();

      if (!userCheck.exists) {
        alert('아이디가 존재하지 않습니다.');
        return;
      }

      // Step 2: Check if the password matches
      const checkPasswordResponse = await fetch('https://ai.doowon.ac.kr:1804/api/checkPassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: id, pw: password }),
      });

      const passwordCheck = await checkPasswordResponse.json();

      if (passwordCheck.valid) {
        // 로그인 성공 시 서버에 토큰 저장 요청
        await fetch('https://ai.doowon.ac.kr:1804/api/saveToken', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: id, token: passwordCheck.token }),
        });
        console.log(`로그인 토큰: ${passwordCheck.token}`); // 토큰 표시
        // localStorage에 토큰 저장
        localStorage.setItem('token', passwordCheck.token);

        alert(`로그인 성공: ${id}`);
        setIsLoggedIn(true); // 로그인 상태 업데이트
        setLoginOpen(false);
        navigate('/overview');
      } else {
        alert('비밀번호가 잘못되었습니다.');
      }
    } catch (error) {
      console.error('Error during login:', error);
      alert('서버 오류로 인해 로그인할 수 없습니다.');
    }
  };

  const handleSignup = async () => {
    try {
      // 이메일 중복 확인 및 임시 비밀번호 전송 요청
      const response = await fetch('https://ai.doowon.ac.kr:1804/api/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: id }),
      });
  
      if (response.ok) {
        alert('임시 비밀번호가 이메일로 전송되었습니다.');
        setSignupOpen(false); // 모달 닫기
      } else {
        const errorMessage = await response.text();
        alert(errorMessage);
      }
    } catch (error) {
      console.error('Error during signup:', error);
      alert('서버 오류로 인해 회원가입을 할 수 없습니다.');
    }
  };

  // 수정된 부분: 로그아웃 함수에서 로그인 상태 업데이트
  const logout = async () => {
    try {
      // 서버에서 토큰 삭제
      await fetch('https://ai.doowon.ac.kr:1804/api/deleteToken', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: id }),
      });
      // localStorage에서 토큰 삭제
      localStorage.removeItem('token');

      alert('로그아웃 되었습니다.');
      setIsLoggedIn(false); // 로그인 상태 업데이트
      toggleMenu();
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error);
      alert('서버 오류로 인해 로그아웃할 수 없습니다.');
    }
  };

  return (
    <header className="App-header">
      {/* 왼쪽 섹션: 햄버거 버튼과 로그인/회원가입 메뉴 */}
      <div className="left-section">
        <div className="menu-icon" onClick={toggleMenu}>
          <span></span><span></span><span></span>
        </div>
      </div>
        
        {/* 메뉴가 열렸을 때 로그인 및 회원가입 메뉴 표시 */}
        {menuOpen && (
          <div className="menu">
            {isLoggedIn ? (
              <div className="menu-container">
                <button onClick={() => { navigate('/settings'); toggleMenu(); }}>설정</button>
                <button onClick={() => { navigate('/overview'); toggleMenu(); }}>전체보기</button>
                <button onClick={logout}>로그아웃</button>
              </div>
            ) : (
                <div className="menu-container">
                  <button onClick={() => { setMenuOpen(false); setLoginOpen(true); }}>로그인</button>
                  <button onClick={() => { setMenuOpen(false); setSignupOpen(true); }}>회원가입</button>
                  <button onClick={() => { setMenuOpen(false); setSignupOpen(true); }}>비밀번호잊음</button>
                  <button onClick={() => { setMenuOpen(false); setLoginOpen(false); setSignupOpen(false); }}>취소</button>
                </div>
            )}
          </div>
        )}
      

      {/* 중앙 제목 */}
      <h1 className="header-title">IoT PLC</h1>
      <h6>mqtt</h6><div className={`led-indicator ${isConnected ? 'led-on' : 'led-off'}`}></div>

      {/* 로그인 모달 */}
      {loginOpen && (
        <div className="menu">
          <div className="menu-container">
            <input
              type="text"
              placeholder="아이디"
              value={id}
              onChange={(e) => setId(e.target.value)}
            />
            <input
              type="password"
              placeholder="비밀번호"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={handleLogin}>로그인</button>
            <button onClick={() => setLoginOpen(false)}>취소</button>
          </div>
        </div>
      )}

      {/* 회원가입 모달 */}
      {signupOpen && (
        <div className="menu">
          <div className="menu-container">
            <input
              type="text"
              placeholder="email 주소"
              value={id}
              onChange={(e) => setId(e.target.value)}
            />
            <button onClick={handleSignup}>임시 비밀번호를 메일로 보냄</button>
            <button onClick={() => setSignupOpen(false)}>취소</button>
          </div>
        </div>
      )}

    </header>
  );
}

export default Header;
