// components/MachineOverview.tsx

import React, { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import './MachineOverview.css';
import { useNavigate } from 'react-router-dom';
import { updateRecord } from './DataHandler';
import { FaTrash } from 'react-icons/fa'; // FontAwesome 아이콘 사용
import { deleteRecord } from './DataHandler';

interface Record {
  mac: string;
  name?: string;
  in?: number[];
  out?: number[];
  type?: number;
  temp?: number;
  humi?: number;
}

interface MachineOverviewProps {
  mqttMessage: string;
}

const MachineOverview: React.FC<MachineOverviewProps> = ({ mqttMessage }) => {
  const [email, setEmail] = useState('');
  const [records, setRecords] = useState<Record[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const email = token ? (jwt_decode(token) as { email: string }).email : '';
    setEmail(email);
  }, []);

  useEffect(() => {
    if (email) {
      fetch('https://ai.doowon.ac.kr:1804/api/findArray', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })
        .then((response) => response.json())
        .then((data) => {
          setRecords(data);
        })
        .catch((error) => console.error('Error fetching records:', error));
    }
  }, [email]);

  useEffect(() => {
    if (mqttMessage && email) {
      try {
        const parsedMessage = JSON.parse(mqttMessage);

        if (parsedMessage.email === email) {
          setRecords((prevRecords) => {
            const existingRecordIndex = prevRecords.findIndex(
              (record) => record.mac === parsedMessage.mac
            );

            if (existingRecordIndex >= 0) {
              return prevRecords.map((record, index) =>
                index === existingRecordIndex
                  ? {
                      ...record,
                      in: parsedMessage.in,
                      out: parsedMessage.out,
                      type: parsedMessage.type,
                      temp: parsedMessage.temp,
                      humi: parsedMessage.humi,
                    }
                  : record
              );
            }

            return [
              ...prevRecords,
              {
                mac: parsedMessage.mac,
                name: parsedMessage.name || parsedMessage.mac,
                in: parsedMessage.in || [],
                out: parsedMessage.out || [],
                type: parsedMessage.type,
                temp: parsedMessage.temp,
                humi: parsedMessage.humi,
              },
            ];
          });

          updateRecord(parsedMessage.mac, parsedMessage).catch((error) =>
            console.error('Error upserting data:', error)
          );
        }
      } catch (error) {
        console.error('MQTT 메시지 파싱 오류:', error);
      }
    }
  }, [mqttMessage, email]);

  const handleDelete = async (mac: string) => {
    if (window.confirm('정말로 삭제하시겠습니까?')) {
      try {
        await deleteRecord(mac);
        setRecords((prevRecords) => prevRecords.filter((record) => record.mac !== mac));
        alert('삭제되었습니다.');
      } catch (error) {
        console.error('Error deleting record:', error);
        alert('삭제 중 오류가 발생했습니다.');
      }
    }
  };
  

  return (
    <div className="machine-overview">

      <table className="machine-table">
        <thead>
          <tr className="table-header">
            <th>이름</th>
            <th>모니터</th>
          </tr>
        </thead>
        <tbody>
          {records.length > 0 ? (
            records.map((record) => (
              <tr key={record.mac}>
                <td>
                  <span
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                    onClick={() => navigate(`/device/${record.mac}`)}
                  >
                    {record.name && Array.isArray(record.name) && record.name.length > 0 ? record.name[0] : record.mac}
                  </span>
                </td>
                <td>
                  <div className="monitor-container">
                    <div className="led-row">
                      <span>&nbsp;입력:</span>
                      {record.in?.map((state, index) => (
                        <div
                          key={`in-${index}`}
                          className={`led ${state === 1 ? 'led-on' : 'led-off'}`}
                        ></div>
                      ))}

                      <span>&nbsp;&nbsp;출력:</span>
                      {record.out?.map((state, index) => (
                        <div
                          key={`out-${index}`}
                          className={`led ${state === 1 ? 'led-on' : 'led-off'}`}
                        ></div>
                      ))}
                    
                      {(record.temp !== undefined || record.humi !== undefined) && (
                        <span>&nbsp;
                          {record.temp !== undefined && <span>&nbsp;&nbsp;온도: {record.temp}°C</span>}
                          {record.humi !== undefined && <span>&nbsp;&nbsp;습도: {record.humi}%</span>}
                          </span>
                      )}
                      {/* 휴지통 아이콘 추가 */}
                      <FaTrash
                        style={{ cursor: 'pointer', marginLeft: '10px', color: 'red' }}
                        onClick={() => handleDelete(record.mac)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={2}>등록된 장치가 없습니다.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default MachineOverview;
