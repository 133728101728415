// components\ImageGallery.tsx

import React from 'react';
import purchaseIcon from '../assets/purchase.png'; // 구매 아이콘 경로
import naverIcon from '../assets/naverShop.jpg'; // 구매 아이콘 경로
import coupangIcon from '../assets/coupangShop.png'; // 구매 아이콘 경로
import cafe24Icon from '../assets/cafe24Shop.png'; // 구매 아이콘 경로
import './ImageGallery.css'; // 스타일 파일을 추가로 불러옵니다.
import image1 from '../assets/i2r-01.jpg'; // 이미지 경로
import image2 from '../assets/i2r-02.jpg'; // 이미지 경로
import image3 from '../assets/i2r-03.jpg'; // 이미지 경로
import image4 from '../assets/i2r-04.jpg'; // 이미지 경로

const images = [
  { src: image1, 
    name: '[i2r-01] RS232, RS485, WiFi, BLE 통신모듈', 
    description: '와이파이, 블루투스, RS232, RS485 를 조합해서 사용 입력전압: 5~30V',
    link: 'https://smartstore.naver.com/i2r/products/8284829279',
    linkCoupang: 'https://www.coupang.com/vp/products/8384574218?itemId=24232344339&vendorItemId=91249522173&q=i2r-01&itemsCount=36&searchId=e746dc1c2291095&rank=0&searchRank=0&isAddedCart=',
    linkCafe24: 'https://i2rlink.cafe24.com/shop3/product/detail.html?product_no=15&item_code=P000000P000A&utm_content=YT3-40nRTfM886HmD-KEsTv2O8PvGZQUMwJhv3AFNC8f1ItX0Ph9folW3QwbVwnCpKopolxNa39N1f-V5dIRiv2-UA&utm_term=UCV68qKnBcZ-LR5hWnzCRf_A&utm_medium=product_shelf&utm_source=youtube&yts_source=AQArQa_Ca5ufaNKXUgWarODLuMm_PQ4ngpteO0XGj5T0PObgkAiOdns1kBleCGP9EP1tVmzGEwbDslGXwfhcZODoKoDWZEKvb4pHaMlA8HyU2Hxopmf4Dlm2at7b9SpRr3YUf1kbhXsSYni8A4nKJo6dSGqfL2kWrA2VXb1XduJiWm0vopyW1YiKDwQ&yt_act=20250112121413#none'
  },
  { src: image2, 
    name: '[i2r-02] IoT PLC (4채널 릴레이)', 
    description: '와이파이 블루투스 통신을 이용해 2접점 릴레이 제어 입력전,압: 5~30V, 30A 릴레이 1개, 10A 릴레이 3개',
    link: 'https://smartstore.naver.com/i2r/products/8285060920',
    linkCoupang: 'https://www.coupang.com/vp/products/8540239931?itemId=24232155157&vendorItemId=91249335893&q=i2r-02&itemsCount=36&searchId=c903fb952577158&rank=0&searchRank=0&isAddedCart=',
    linkCafe24: 'https://i2rlink.cafe24.com/shop3/product/detail.html?product_no=13&item_code=P000000N000A&utm_content=YT3-vXE7oBJYjcvHGAecxTVucBV7_kU7CVF0kVgHEnDcmifZFeLGMZUuU35xAHladGRNHPPI39sizxMTaL7zxC3mIQ&utm_term=UCV68qKnBcZ-LR5hWnzCRf_A&utm_medium=product_shelf&utm_source=youtube&yts_source=AQArQa-c_wNsmj_VogjJsiGEK1Pe4D4PmVochzswGe2pqpFXxtVzE_sxQaxafVPUa24CJV8_CJyCqddyE7Yq6YJYxkpiiBFQaKEGDAmf4XZy2YG1G99CpUNjOOfn7Ot_VYRctkOc-ztmU6bmNK5kwz7mRODhxyhzwS7xyeG6UvBmz0vHMufGxaw7TOI&yt_act=20250112131212'
  },
  { src: image3, 
    name: '[i2r-03] IoT PLC (4채널 릴레이)', 
    description: '와이파이 블루투스 통신을 이용해 2접점 릴레이 제어, 온도 습도 센서 내장 입력전압: 5~30V, 30A 릴레이 1개, 10A 릴레이 3개',
    link: 'https://smartstore.naver.com/i2r/products/8285011211',
    linkCoupang: 'https://www.coupang.com/vp/products/8540260539?itemId=24232021891&vendorItemId=91249204256&q=i2r-03&itemsCount=36&searchId=54a1a8f934843848&rank=0&searchRank=0&isAddedCart=',
    linkCafe24: 'https://i2rlink.cafe24.com/shop3/product/detail.html?product_no=14&item_code=P000000O000A&utm_content=YT3-OneKvl37S9gz9Pbz_v75Pw_jo4Rspi-JUBqbKgmgIgy7nsqpDDkTfEIdpm5_R2NJO4_A9z8yaqEPXG1pGmDakw&utm_term=UCV68qKnBcZ-LR5hWnzCRf_A&utm_medium=product_shelf&utm_source=youtube&yts_source=AQArQa_mD7W0tUQxetZAzIOFiLGcUmNnDYmCZIXFTiO-Jhnk_XkuGir8RAMsVc8x1k3Y1kzSwKfcJMyQyzHEkEFQ53_CqIShZ6xw1ajsSl8tWFjEGuVeUHxPBt9LuvhM2JmdWjc7a1phZDOJ88eW5FzZDjnHjJYSsXuAzbrncPcLk35idURM-Tj23OY&yt_act=20250112131311'
  },
  { src: image4, 
    name: '[i2r-04] IoT PLC (8채널 릴레이)', 
    description: '와이파이 블루투스 통신을 이용해 2접점 릴레이 제어 입력전압: 5~30V, 10A 릴레이 8개',
    link: 'https://smartstore.naver.com/i2r/products/9524935681',
    linkCoupang: 'https://www.coupang.com/vp/products/8540228133?itemId=24232241804&vendorItemId=91249421175&q=i2r-04&itemsCount=36&searchId=f3eb84f131775940&rank=0&searchRank=0&isAddedCart=',
    linkCafe24: 'https://i2rlink.cafe24.com/shop3/product/detail.html?product_no=12&item_code=P000000M000A&utm_content=YT3-4QJDC1vDXOyp8-wa63W-m3uqaAeUXQ1BghZKiMccFyM10Mg9KHi-ktNt-VXLQTMrAOeebtmhsoHRZSKuGlwBOA&utm_term=UCV68qKnBcZ-LR5hWnzCRf_A&utm_medium=product_shelf&utm_source=youtube&yts_source=AQArQa9adBphJ57zjh9waDMjwQn5CtjmjI-7KEM5sxHPPEpWvwxFKI6AcVQ7OijeKpVuYCv3DiJiGnFjNp74dTtsbamUv0PU8XbQFDgc4LPBVXeHGxeddRqoX5EHQFXieBqpcfA_QrEHa0Wyqm6hkTLKb8agYiOwVuhRFVQOIvxqUMPX9nysheAc04o&yt_act=20250112131335'
  }
];

const ImageGallery: React.FC = () => {
  return (
    <div>
      <div className="image-gallery">
        {images.map((image, index) => (
          <div key={index} className="image-container">
            <img src={image.src} alt={image.name} className="image" />
            <div className="image-name">{image.name}</div>
            <div className="image-description">
              {image.description}
            </div>
            <div className="image-link">
              {image.link && (
                <a href={image.link} target="_blank" rel="noopener noreferrer">
                  <img src={naverIcon} alt="구매" className="purchase-icon" />
                </a>
              )}
              {image.linkCoupang && (
                <a href={image.linkCoupang} target="_blank" rel="noopener noreferrer">
                  <img src={coupangIcon} alt="구매" className="purchase-icon" />
                </a>
              )}
              {image.linkCafe24 && (
                <a href={image.linkCafe24} target="_blank" rel="noopener noreferrer">
                  <img src={cafe24Icon} alt="구매" className="purchase-icon" />
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;
