// components\DataHandler.tsx

export async function updateRecord(mac: string, updates: any) {
  //console.log('MAC:', mac);
  //console.log('Updates:', updates);
  // _id 필드 제거
  const { _id, order, ...filteredUpdates } = updates;
  //console.log('===============Updates after filtering _id:', filteredUpdates);
  // order 필드가 존재하면 업서트 중단
  if (order !== undefined) {
    console.log(`Skipped upsert: 'order' field exists in updates for MAC: ${mac}`);
    return { message: 'Skipped due to order field presence' };
  }
  try {
    const response = await fetch('https://ai.doowon.ac.kr:1804/api/upsert', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ mac, ...filteredUpdates }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error updating record:', error);
    throw error;
  }
}

  // DataHandler.tsx
export async function updateName(mac: string, nameArray: string[]): Promise<any> {
  try {
    const response = await fetch('https://ai.doowon.ac.kr:1804/api/updateName', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ mac, name: nameArray }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating name array:', error);
    throw error;
  }
}

export async function deleteRecord(mac: string): Promise<any> {
  try {
    const response = await fetch('https://ai.doowon.ac.kr:1804/api/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ mac }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error deleting record:', error);
    throw error;
  }
}

  