// components\ModalLed.tsx

import React, { useEffect, useState } from 'react';
import './ModalLed.css';
import MQTTService from './MQTTService';
import jwt_decode from 'jwt-decode';

interface DeviceRecord {
  mac: string;
  name?: string[];
  in?: number[];
  out?: number[];
}

interface ModalProps {
  onCancel: () => void;
  labelNo: number; // LED 번호를 전달받기 위한 prop
  deviceMac: string; // MAC 주소를 전달받기 위한 새로운 prop
  name: string[]; // name 배열
  mqttMessage: string; // 추가된 mqttMessage prop
}

const ModalLed = ({ onCancel, labelNo, deviceMac, name, mqttMessage }: ModalProps) => {
  const [records, setRecords] = useState<DeviceRecord[]>([]);
  const [selectedOnDevice, setSelectedOnDevice] = useState<DeviceRecord | null>(null);
  const [selectedOffDevice, setSelectedOffDevice] = useState<DeviceRecord | null>(null);
  const [selectedOnPort, setSelectedOnPort] = useState<number | null>(null);
  const [selectedOffPort, setSelectedOffPort] = useState<number | null>(null);
  const [selectedOnState, setSelectedOnState] = useState<boolean | null>(null);
  const [selectedOffState, setSelectedOffState] = useState<boolean | null>(null);
  const [email, setEmail] = useState<string | null>(null);

  // mqttMessage 변경 시 분석하여 상태 업데이트
  useEffect(() => {
    if (!mqttMessage) return;

    try {
      const parsedMessage = JSON.parse(mqttMessage);

      // MAC 주소와 LED 번호가 일치하는 메시지만 처리
      if (
        parsedMessage.order === 6 &&
        parsedMessage.mac === deviceMac &&
        parsedMessage.portNo === labelNo
      ) {
        // "off" 상태 설정
        const offState = parsedMessage.portState.find((state: any) => !state.value);
        if (offState) {
          const device = records.find((r) => r.mac === offState.mac);
          setSelectedOffDevice(device || null); // 매칭된 기기
          setSelectedOffPort(offState.port); // 포트 값 저장
          setSelectedOffState(false);
        }

        // "on" 상태 설정
        const onState = parsedMessage.portState.find((state: any) => state.value);
        if (onState) {
          const device = records.find((r) => r.mac === onState.mac);
          setSelectedOnDevice(device || null); // 매칭된 기기
          setSelectedOnPort(onState.port); // 포트 값 저장
          setSelectedOnState(true);
        }
      }
    } catch (error) {
      console.error('Error parsing MQTT message:', error);
    }
  }, [mqttMessage, deviceMac, labelNo]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken: any = jwt_decode(token);
        setEmail(decodedToken.email);
      } catch (error) {
        console.error('토큰 디코딩 오류:', error);
      }
    } else {
      console.error('토큰이 없습니다.');
    }
  }, []);

  useEffect(() => {
    if (!email) return;

    fetch('https://ai.doowon.ac.kr:1804/api/findArray', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => response.json())
      .then((data: DeviceRecord[]) => {
        setRecords(data);
      })
      .catch((error) => {
        console.error('기기 목록 가져오기 실패:', error);
      });
  }, [email]);

  const handleSave = () => {
    if (
      selectedOnDevice &&
      selectedOffDevice &&
      selectedOnPort !== null &&
      selectedOffPort !== null &&
      selectedOnState !== null &&
      selectedOffState !== null
    ) {
      const sendMessage = JSON.stringify({
        order: 5,
        oper: 'save',
        mac: deviceMac, // props에서 전달된 MAC 주소 사용
        portNo: labelNo,
        portState: [
          {
            mac: selectedOffDevice.mac,
            port: selectedOffPort,
            value: selectedOffState, // Off 상태
          },
          {
            mac: selectedOnDevice.mac,
            port: selectedOnPort,
            value: selectedOnState, // On 상태
          },
        ],
      });

      const topic = `i2r/${email}/in`;
      //console.log("========= save mqtt : ", sendMessage);
      MQTTService.getInstance().publish(topic, sendMessage);
      console.log(`Published to ${topic}: ${sendMessage}`);
    }
    onCancel();
  };

  const handleDelete = () => {
    const sendMessage = JSON.stringify({
      order: 5,
      mac: deviceMac, // props에서 전달된 MAC 주소 사용
      oper: 'delete',
      portNo: labelNo, // 전달받은 LED 번호 사용
    });
  
    const topic = `i2r/${email}/in`;
    //console.log("delete mqtt : ", sendMessage);
    MQTTService.getInstance().publish(topic, sendMessage);
    //console.log(`Published to ${topic}: ${sendMessage}`);
  
    onCancel(); // 모달 창을 닫음
  };

  
  const handleView = () => {
    const sendMessage = JSON.stringify({
      order: 5,
      mac: deviceMac, // props에서 전달된 MAC 주소 사용
      oper: 'list',
      portNo: labelNo, // 전달받은 LED 번호 사용
    });
  
    const topic = `i2r/${email}/in`;
    //console.log("========= view mqtt : ", sendMessage);
    MQTTService.getInstance().publish(topic, sendMessage);
    //console.log(`Published to ${topic}: ${sendMessage}`);
  };  


  return (
    <div className="modal">
      <h3>{name?.[labelNo+1] || 'Unnamed'}</h3>
      {/*<p style={{ fontSize: '14px', color: 'gray' }}>MQTT 메시지: {mqttMessage}</p>*/}
      <div>
        <h4>On 될 때 동작</h4>
        {records.length > 0 ? (
          <div>
            <select
              value={selectedOnDevice?.mac || ''}
              onChange={(e) => {
                const device = records.find((r) => r.mac === e.target.value);
                setSelectedOnDevice(device || null);
                setSelectedOnPort(null);
                setSelectedOnState(null); // 상태 초기화
              }}
            >
              <option value="">기기를 선택하세요</option>
              {records.map((record, index) => (
                <option key={index} value={record.mac}>
                  {record.name?.[0] || 'Unknown Device'}
                </option>
              ))}
            </select>
            
            {selectedOnDevice && selectedOnDevice.out && (
              <select
                value={selectedOnPort ?? ''}
                onChange={(e) => setSelectedOnPort(Number(e.target.value))}
              >
                <option value="" disabled>
                  포트를 선택하세요
                </option>
                {selectedOnDevice.out.map((_, index) => {
                  const ledCount = selectedOnDevice.in?.length || 0;
                  return (
                    <option key={index} value={index}>
                      {selectedOnDevice.name?.[index + 1 + ledCount] || `스위치 ${index + 1}`}
                    </option>
                  );
                })}
              </select>
            )}
            {selectedOnPort !== null && (
              <select
                value={selectedOnState !== null ? selectedOnState.toString() : ''}
                onChange={(e) => setSelectedOnState(e.target.value === 'true')}
              >
                <option value="" disabled>
                  상태를 선택하세요
                </option>
                <option value="true">On</option>
                <option value="false">Off</option>
              </select>
            )}
          </div>
        ) : (
          <p>등록된 기기가 없습니다.</p>
        )}
      </div>

      <div>
        <h4>Off 될 때 동작</h4>
        {records.length > 0 ? (
          <div>
            <select
              value={selectedOffDevice?.mac || ''}
              onChange={(e) => {
                const device = records.find((r) => r.mac === e.target.value);
                setSelectedOffDevice(device || null);
                setSelectedOffPort(null);
                setSelectedOffState(null); // 상태 초기화
              }}
            >
              <option value="">기기를 선택하세요</option>
              {records.map((record, index) => (
                <option key={index} value={record.mac}>
                  {record.name?.[0] || 'Unknown Device'}
                </option>
              ))}
            </select>
            {selectedOffDevice && selectedOffDevice.out && (
              <select
                value={selectedOffPort ?? ''}
                onChange={(e) => setSelectedOffPort(Number(e.target.value))}
              >
                <option value="" disabled>
                  포트를 선택하세요
                </option>
                {selectedOffDevice.out.map((_, index) => {
                  const ledCount = selectedOffDevice.in?.length || 0;
                  return (
                    <option key={index} value={index}>
                      {selectedOffDevice.name?.[index + 1 + ledCount] || `스위치 ${index + 1}`}
                    </option>
                  );
                })}
              </select>
            )}
            {selectedOffPort !== null && (
              <select
                value={selectedOffState !== null ? selectedOffState.toString() : ''}
                onChange={(e) => setSelectedOffState(e.target.value === 'true')}
              >
                <option value="" disabled>
                  상태를 선택하세요
                </option>
                <option value="true">On</option>
                <option value="false">Off</option>
              </select>
            )}
          </div>
        ) : (
          <p>등록된 기기가 없습니다.</p>
        )}
      </div>

      <div>
        <button onClick={handleView}>보기</button>
        <button onClick={handleSave}>저장</button>
        <button onClick={handleDelete}>삭제</button>
        <button onClick={onCancel}>취소</button>
      </div>
    </div>
  );
};

export default ModalLed;
