// components\MQTTClient.tsx

import React, { useEffect } from 'react';
import jwt_decode from 'jwt-decode'; // JWT 디코딩
import MQTTService from './MQTTService';

interface MQTTClientProps {
  onMessage: (message: string) => void;
}

const MQTTClient: React.FC<MQTTClientProps> = ({ onMessage }) => {
  const token = localStorage.getItem('token');
  const email = token ? (jwt_decode(token) as { email: string }).email : '';
  const targetTopic = `i2r/${email}/out`;
  const commonTopic = 'i2r/+/out'; // 공통 토픽

  useEffect(() => {
    const mqttClient = MQTTService.getInstance().getClient();

    // 메시지 핸들러 정의
    const handleMessage = (topic: string, message: Buffer) => {
      const messageStr = message.toString();
      console.log(`Received message on topic ${topic}: ${messageStr}`);
      onMessage(messageStr);
    };

    // 공통 토픽 구독
    mqttClient.subscribe(commonTopic, (err) => {
      if (!err) {
        console.log(`Subscribed to common topic: ${commonTopic}`);
      } else {
        console.error(`Failed to subscribe to common topic: ${commonTopic}`, err);
      }
    });

    // 메시지 핸들러 등록
    mqttClient.on('message', handleMessage);

    return () => {
      // 컴포넌트 언마운트 시 리스너 및 구독 해제
      mqttClient.off('message', handleMessage); // 리스너 제거
      mqttClient.unsubscribe(commonTopic, () => {
        console.log(`Unsubscribed from common topic: ${commonTopic}`);
      });
    };
  }, [commonTopic, onMessage]);

  //return <div>MQTT 연결 중...</div>;
  return null;
};

export default MQTTClient;
