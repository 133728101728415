// components/ModalSwitch.tsx

import React, { useEffect, useState } from 'react';
import './ModalLed.css';
import MQTTService from './MQTTService';
import jwt_decode from 'jwt-decode';

interface ModalSwitchProps {
  deviceMac: string; // MAC 주소를 전달받기 위한 새로운 prop
  onCancel: () => void;
  labelNo: number;
  currentLabel: string;
  mqttMessage: string; // 추가된 mqttMessage prop
}

const ModalSwitch: React.FC<ModalSwitchProps> = ({ deviceMac, onCancel, labelNo, currentLabel, mqttMessage }) => {
  const [email, setEmail] = useState<string | null>(null);
  const [newLabel, setNewLabel] = useState(currentLabel);
  const [startTime, setStartTime] = useState('09:00');
  const [endTime, setEndTime] = useState('17:00');
  const [repeatMode, setRepeatMode] = useState('d'); // "d" (daily) 또는 "w" (weekly)
  const [selectedDays, setSelectedDays] = useState<number[]>([]); // 요일 선택 저장
  const [schedule, setSchedule] = useState<string>(''); // 표시용 스케줄 문자열
  const [scheduleLogs, setScheduleLogs] = useState<string[]>([]); // 메시지 기록 리스트


  // mqttMessage 변경 시 분석하여 상태 업데이트
  const dayMapping = ['일', '월', '화', '수', '목', '금', '토'];
  useEffect(() => {
    if (!mqttMessage) return;
  
    try {
      const parsedMessage = JSON.parse(mqttMessage);
  
      // MAC 주소와 라벨 번호가 일치하는 메시지 처리
      if (
        parsedMessage.order === 4 &&
        parsedMessage.mac === deviceMac &&
        parsedMessage.pI === labelNo
      ) {
        const { sH, sM, eH, eM, rM, dW, pN } = parsedMessage;
  
        const startTime = `${sH}:${sM < 10 ? `0${sM}` : sM}`;
        const endTime = `${eH}:${eM < 10 ? `0${eM}` : eM}`;
        const repeat =
          rM === 'w'
            ? `매주 ${Array.isArray(dW) ? dW.map(day => dayMapping[day]).join(', ') : dayMapping[dW]}`
            : '매일';
        const newLog = `${startTime} ~ ${endTime} ${repeat} ${pN}`;
  
        // 새로운 메시지를 기록에 추가
        setScheduleLogs((prevLogs) => [...prevLogs, newLog]);
      }
    } catch (error) {
      console.error('Error parsing MQTT message:', error);
    }
  }, [mqttMessage, deviceMac, labelNo]);
  
  useEffect(() => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decodedToken: any = jwt_decode(token);
          setEmail(decodedToken.email);
        } catch (error) {
          console.error('토큰 디코딩 오류:', error);
        }
      } else {
        console.error('토큰이 없습니다.');
      }
    }, []);

  const handleView = () => {
    const sendMessage = {
      order: 4,
      mac: deviceMac, // 적절한 MAC 주소 설정
      oper: 'list', // 리스트 작업
      pI: labelNo, // 핀 번호
    };

    // MQTT 메시지를 발행
    const topic = `i2r/${email}/in`;
    MQTTService.getInstance().publish(topic, JSON.stringify(sendMessage));

    // "보기" 버튼 클릭 시 기록 초기화
    setScheduleLogs([]);
  };

  const handleSave = () => {
    const [sH, sM] = startTime.split(':').map(Number);
    const [eH, eM] = endTime.split(':').map(Number);
  
    const schedule = {
      sH,
      sM,
      eH,
      eM,
      rM: repeatMode,
    };
  
    const daysToSend = repeatMode === 'w' ? selectedDays : [0]; // 반복 모드가 "w"인 경우만 요일 배열 사용, 아닌 경우 기본값 [0]
  
    daysToSend.forEach((day) => {
      const sendMessage = {
        order: 4,
        mac: deviceMac,
        oper: 'insert',
        pI: labelNo,
        dW: day, // 요일 값 하나씩 설정
        ...schedule,
      };
  
      // MQTT 메시지 발행
      const topic = `i2r/${email}/in`;
      MQTTService.getInstance().publish(topic, JSON.stringify(sendMessage));
      //console.log(`============메시지가 전송되었습니다: ${JSON.stringify(sendMessage)}`);
    });
    // "보기" 버튼 효과 실행
    setTimeout(() => {
      handleView();
    }, 1000); // 약간의 딜레이를 줘 상태가 업데이트될 시간을 확보
  };
  
  const toggleDaySelection = (day: number) => {
    setSelectedDays((prev) =>
      prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
    );
  };

  const handleClearAll = () => {
    const mqttClient = MQTTService.getInstance().getClient();
    const clearMessage = {
      order: 4,
      mac: deviceMac,
      oper: "deleteAll",
      pI: labelNo
    };
    const topic = `i2r/${email}/in`;
    mqttClient.publish(topic, JSON.stringify(clearMessage));
    console.log(`Sent message to clear all: ${JSON.stringify(clearMessage)}`);
    // "보기" 버튼 효과 실행
    handleView();
  };

  const handleDelete = (slotIndex: number) => {
    const mqttClient = MQTTService.getInstance().getClient();
    const deleteMessage = {
      order: 4,
      mac: deviceMac,
      oper: "delete",
      pI: labelNo,
      slotIndex: slotIndex
    };
    const topic = `i2r/${email}/in`;
    mqttClient.publish(topic, JSON.stringify(deleteMessage));
    // "보기" 버튼 효과 실행
    handleView();
  };

  return (
    <div className="modal">
      <h3>스위치 {labelNo + 1}</h3>
      {/*<p style={{ fontSize: '14px', color: 'gray' }}>MQTT 메시지: {mqttMessage}</p>*/}
      
      {/* 메시지 로그 표시 */}
      <div style={{ maxHeight: '200px', overflowY: 'auto', border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
        {scheduleLogs.map((log, index) => (
          <p key={index} style={{ fontSize: '16px', color: 'black' }}>
            {log} <span role="img" aria-label="trash"><button onClick={() => handleDelete(index)}>🗑️</button></span>
          </p>
        ))}
      </div>

      <div>
        <label>시작 시간:</label>
        <input
          type="time"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
        />
      </div>

      <div>
        <label>종료 시간:</label>
        <input
          type="time"
          value={endTime}
          onChange={(e) => setEndTime(e.target.value)}
        />
      </div>

      <div>
        <label>반복 설정:</label>
        <select
          value={repeatMode}
          onChange={(e) => setRepeatMode(e.target.value)}
        >
          <option value="d">매일</option>
          <option value="w">매주</option>
        </select>
      </div>

      {repeatMode === 'w' && (
        <div>
          <label>요일 선택:</label>
          <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
            {['일', '월', '화', '수', '목', '금', '토'].map((day, index) => (
              <button
                key={index}
                className={selectedDays.includes(index) ? 'selected-day' : ''}
                onClick={() => toggleDaySelection(index)}
                style={{
                  padding: '5px 10px',
                  border: '1px solid #ccc',
                  background: selectedDays.includes(index) ? '#007bff' : '#fff',
                  color: selectedDays.includes(index) ? '#fff' : '#000',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
              >
                {day}
              </button>
            ))}
          </div>
        </div>
      )}

      <div style={{ marginTop: '20px' }}>
        <button onClick={handleView}>보기</button>
        <button onClick={handleSave}>저장</button>
        <button onClick={handleClearAll}>모두 지우기</button>
        <button onClick={onCancel}>취소</button>
      </div>
    </div>
  );
};

export default ModalSwitch;

