// components\MQTTService.tsx
import mqtt from 'mqtt';

class MQTTService {
  private static instance: MQTTService;
  private client: mqtt.MqttClient;
  private brokerUrl = 'wss://ai.doowon.ac.kr:1803'; // 고정 브로커 URL

  private constructor() {
    this.client = mqtt.connect(this.brokerUrl);

    this.client.on('connect', () => {
      console.log('Connected to MQTT broker');
    });

    this.client.on('error', (error) => {
      console.error('MQTT Connection Error:', error);
    });
  }

  public static getInstance(): MQTTService {
    if (!MQTTService.instance) {
      MQTTService.instance = new MQTTService();
    }
    return MQTTService.instance;
  }

  public getClient(): mqtt.MqttClient {
    return this.client;
  }

  public publish(topic: string, message: string): void {
    this.client.publish(topic, message, (err) => {
      if (err) {
        console.error('Publish Error:', err);
      } else {
        console.log(`Message published to ${topic}: ${message}`);
      }
    });
  }
}

export default MQTTService;
