// App.tsx

import React, { useState } from 'react';
import './App.css';
import Header from './components/Header';
import MachineOverview from './components/MachineOverview';
import Settings from './components/Settings';
import { TokenProvider } from './components/TokenContext';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import MQTTClient from './components/MQTTClient';
import DeviceDetail from './components/DeviceDetail';
import ImageGallery from './components/ImageGallery'; 
import YoutubeGallery from './components/YoutubeGallery';

function MainContent({ mqttMessage }: { mqttMessage: string }) {
  const location = useLocation();

  return (
    <main>
      {/*현재 경로: {location.pathname}*/}
      <Routes>
        <Route path="/overview" element={<MachineOverview mqttMessage={mqttMessage} />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/device/:mac" element={<DeviceDetail mqttMessage={mqttMessage} />} />
        <Route path="/" element={
            <>
              <ImageGallery />
              <YoutubeGallery />
            </>
          }
        />
        {/* <Route path="/" element={null} /> */}
      </Routes>
    </main>
  );
}

function App() {
  const [mqttMessage, setMqttMessage] = useState<string>('');

  const handleMQTTMessage = (message: string) => {
    //console.log('MQTT Message Received in App:', message); // 메시지 확인
    setMqttMessage(message);
  };

  return (
    <TokenProvider>
      <div className="App">
        <Header />
        <MQTTClient onMessage={handleMQTTMessage} />
        <MainContent mqttMessage={mqttMessage} />
      </div>
    </TokenProvider>
  );
}

export default App;

