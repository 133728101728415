// components\Settings.tsx
import React, { useState } from 'react';

function Settings() {
  const [newPassword, setNewPassword] = useState('');

  const handleChangePassword = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://ai.doowon.ac.kr:1804/api/changePassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ newPassword }),
      });

      if (response.ok) {
        alert('비밀번호가 성공적으로 변경되었습니다.');
      } else {
        alert('비밀번호 변경에 실패했습니다.');
      }
    } catch (error) {
      console.error('Error changing password:', error);
      alert('서버 오류로 인해 비밀번호를 변경할 수 없습니다.');
    }
  };

  return (
    <div>
      <h2>설정</h2>
      <input
        type="password"
        placeholder="새 비밀번호"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <button onClick={handleChangePassword}>비밀번호 변경</button>
    </div>
  );
}

export default Settings;
